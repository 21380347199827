import React, { useCallback, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDropzone } from "react-dropzone";
import csv from "csv";
import CSVPaginationTable from "./CSVTable";
import UploadToOnCore from "./UploadToOnCore";

const useStyles = makeStyles((theme) => ({
  dragZoneControl: {
    backgroundColor: "#f4f4f4",
  },
}));

const transform = (arr) => {
  const header = arr[0];
  const result = [];
  for (let i = 1; i < arr.length; i++) {
    const o = {};
    header.forEach((el, hi) => {
      o[el] = arr[i][hi];
    });
    result.push(o);
  }
  return result;
};

export default function UploadFile(props) {
  const [fileData, setFileData] = useState([]);

  const [file, setFile] = useState(null);

  //const cvsType = props.csvType;
  const classes = useStyles();
  const onDrop = useCallback((files) => {
    const reader = new FileReader();
    reader.onload = () => {
      // Do whatever you want with the file contents
      csv.parse(reader.result, (err, data) => {
        setFileData(transform(data));
      });
      // const binaryStr = reader.result;
      // console.log(binaryStr);
      //console.log(reader.readAsText(file));
    };
    reader.readAsBinaryString(files[0]);
    setFile(files[0]);
    // Do something with the files
  }, []);

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
  } = useDropzone({
    accept: ".csv",
    onDrop,
  });

  const acceptedFilesItems = acceptedFiles.map((file) => (
    <span>
      {file.path} - {file.size} bytes
    </span>
  ));

  return (
    <div>
      <div {...getRootProps()} className={classes.dragZoneControl}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the file here ...</p>
        ) : (
          <div>
            <p> Drag 'n' drop CSV file here, or click to select the file</p>
            <p>
              <em>&nbsp;(Note: Only *.csv files are accepted)</em>
            </p>
          </div>
        )}
      </div>
      <div>
        {fileData !== undefined && fileData.length > 0 ? (
          <div>File uploaded:&nbsp;{acceptedFilesItems}</div>
        ) : null}
      </div>
      <div>
        {fileData !== undefined && fileData.length > 0 ? (
          <div>
            <CSVPaginationTable
              inputRecords={fileData}
              csvType={props.csvType}
            />
            <br />
            <br />
            <UploadToOnCore
              inputRecords={fileData}
              csvType={props.csvType}
              file={file}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}
