import React from "react";
import PropTypes from "prop-types";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import { TableContainer } from "@material-ui/core";
import TableHead from "@material-ui/core/TableHead";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import { useTranslation } from "react-i18next";
import DownloadLink from "./DownloadLink";
import DownloadFailedLink from "./DownloadFailedLink";
import ActivityDialog from "./RecentActivityDialogErrors";
import Check from '@material-ui/icons/Check';
import CircularProgress from '@material-ui/core/CircularProgress';
import LoopIcon from '@material-ui/icons/Loop';
import WarningIcon from '@material-ui/icons/Warning';


const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 12,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  //const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStyles2 = makeStyles({
  table: {
    border: 1,
    minWidth: 500,
  },
});

function CustomPaginationActionsTable(props) {
  const classes = useStyles2();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const { t } = useTranslation();

  const rows = props.recentActivityRecords || [];

  const mapping = {
    "Participants Load": "participant-registration",
    "Continuing Review": "continuing-review",
    "Load Protocol Data": "training-data",
  };

  function doFilterByType(r) {
    return r.type === mapping[props.filterByType];
  }

  const filteredRows = props.filterByType ? rows.filter(doFilterByType) : rows;

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, filteredRows.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getStatusIcon = (recentEntryStatus) => {
    //Uploaded -> Parsed -> Processing -> (Failed/Finished)
    if (recentEntryStatus === 'Finished') {
      return <Check size={20} color="primary" />;
    } else if (recentEntryStatus === 'Uploaded') {
      return <LoopIcon size={20} color="primary" />;
    } else if (recentEntryStatus=== 'Parsed' || recentEntryStatus=== 'Processing') {
      return <CircularProgress size={20} color="primary"/>;
    } else if (recentEntryStatus=== 'Failed' || recentEntryStatus=== 'incomplete') {
      return <WarningIcon size={20} color="error"/>;
    }
  };

  const getRecords = (row) => {
    if (row.numRowsProcessed === row.numRows){
      return row.numRows;
    } else {
      return row.numRowsProcessed + '/' +  row.numRows;
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table
        className={classes.table}
        size="small"
        aria-label="custom pagination table"
      >
        <TableHead>
          <TableRow>
            <StyledTableCell align="left">File Name</StyledTableCell>
            <StyledTableCell>Source</StyledTableCell>
            <StyledTableCell align="left">Type</StyledTableCell>
            <StyledTableCell align="left">File Type</StyledTableCell>
            <StyledTableCell align="left">Environment</StyledTableCell>
            <StyledTableCell align="left">Status</StyledTableCell>
            <StyledTableCell align="left">Records</StyledTableCell>
            <StyledTableCell align="left">Failed</StyledTableCell>
            <StyledTableCell align="left">Uploaded By</StyledTableCell>
            <StyledTableCell align="left">Uploaded Date</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? filteredRows.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )
            : filteredRows
          ).map((row) => (
            <StyledTableRow key={row.uploadedDate}>
              <TableCell align="left">
                <DownloadLink
                  href={row.links[1].href}
                  filename={row.filename}
                ></DownloadLink>
              </TableCell>
              <TableCell component="th" scope="row">
                {t(`importSource.${row.source}`)}
              </TableCell>
              <TableCell align="left">{t(`importTypes.${row.type}`)}</TableCell>
              <TableCell align="left">
                {t(`fileTypes.${row.contentType}`)}
              </TableCell>
              <TableCell align="left">
                {t(`oncoreInstances.${row.oncoreInstance}`)}
              </TableCell>
              <TableCell align="left">
                {getStatusIcon(t(`importStatus.${row.status}`))}
                &nbsp;
                {t(`importStatus.${row.status}`)}&nbsp;
                <ActivityDialog
                  filename={row.filename}
                  rowType={t(`importTypes.${row.type}`)}
                  recentActivitySummary={[row.summary]}
                />
              </TableCell>
              <TableCell align="left">{getRecords(row)}</TableCell>
              <TableCell align="left">
                 <DownloadFailedLink row={row}></DownloadFailedLink>
              </TableCell>
              <TableCell align="left">{row.uploadedBy}</TableCell>
              <TableCell align="left">
                {new Date(row.uploadedDate).toLocaleString()}
              </TableCell>
            </StyledTableRow>
          ))}

          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
              colSpan={0}
              count={filteredRows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              //overflow='visible'
              SelectProps={{
                inputProps: { "aria-label": "rows per page" },
                native: true,
                overflow: "visible",
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}

export default CustomPaginationActionsTable;
