import React from "react";
import { useTranslation } from "react-i18next";
import { ASHeader, ASMain, ASFooter } from "@stanford-tds/as-components";
import OISMainContainer from "../components/OISMainContainer";

const AppPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <ASHeader
        sitenameTitle={t("app.title")}
        sitenameSubtitle={t("app.subtitle")}
      />
      <ASMain>
        <OISMainContainer />
      </ASMain>
      <ASFooter
        currentYear={t("app.year")}
        buildVersion={`${process.env.REACT_APP_VERSION}`}
      />
    </>
  );
};

export default AppPage;
