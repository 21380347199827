import React from "react";
import { downloadFromApi } from "../services/downloadService";
import { makeStyles } from "@material-ui/core/styles";
import GetAppIcon from '@material-ui/icons/GetApp';

const useStyles = makeStyles((theme) => ({
    download: {
        verticalAlign: "middle"
    }
}));

function DownloadFailedLink(props) {
  const classes = useStyles();
  var numRowsFailed = props.row.numRowsFailed;
  var links = props.row.links, failedFileLink;

  for (var index = 0; index < links.length; index++) {
    var link = links[index];
    if (link.rel === "failed-file") {
      failedFileLink = link.href;
      break;
    }
  }

  if (failedFileLink) {
    return (

      <a
        href={failedFileLink}
        onClick={evt => {
          evt.preventDefault();
          downloadFromApi(failedFileLink);
        }
        }
      >
         {numRowsFailed}
          <span className={classes.download}><GetAppIcon fontSize='small'></GetAppIcon></span>
      </a>
    );
  }

  return numRowsFailed;
}

export default DownloadFailedLink;
