export const handleCommonBRError = (
  err,
  setASAlertMessage,
  setDisplayASAlert
) => {
  let handled = false;
  if (err.message) {
    handled = true;
    setASAlertMessage({
      type: "error",
      detail: err.message,
    });
    setDisplayASAlert(true);
  } else {
    // failed to reach OIS server, like connection issue etc
    handled = true;
    console.log("OIS Server is not available.", err);
  }
  return handled;
};
