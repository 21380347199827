import React from "react";
import { useAuth } from "./services/authService";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { ThemeProvider } from "styled-components";
import { theme } from "./theme";
import { ASAppPage } from "@stanford-tds/as-components";
import AppPage from "./components/AppPage";

// src/App.js
// App component containing the standard components and configuration for all applications.
// Applications should modify the AppPage component in components/AppPage and should leave this file unmodified.
const App = () => {
  const auth = useAuth();
  const { t } = useTranslation();

  return (
    <div>
      {auth.isAuthenticated && (
        <>
          <Helmet>
            <title>{t("app.title")}</title>
            <meta name="description" content={t("app.description")} />
            <link
              rel="stylesheet"
              href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,200,300,600,700,200italic,300italic,400italic,700italic|Fjord+One"
            />
          </Helmet>
          <ThemeProvider theme={theme}>
            <ASAppPage>
              <AppPage />
            </ASAppPage>
          </ThemeProvider>
        </>
      )}
    </div>
  );
};

export default App;
