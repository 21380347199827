import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";
import UploadFile from "./UploadFile";
import EProtocolDatePickers from "./EProtocolDatePickers";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: 16,
    minWidth: 1520,
  },
}));

export default function NativeSelects({ callBackFilter }) {
  const classes = useStyles();
  const [selectedOption, setSelectedOption] = useState(
    "Select Action below to proceed"
  );

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
    callBackFilter(event.target.value);
  };

  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel shrink htmlFor="action-native-label-placeholder">
          Action: {selectedOption}
        </InputLabel>
        <hr />
        <div>
          <NativeSelect
            value={selectedOption}
            onChange={handleChange}
            inputProps={{
              name: "action",
              id: "action-native-label-placeholder",
            }}
          >
            <option value="">Select Action</option>
            <option value={"Continuing Review"}>Continuing Review</option>
            <option value={"Participants Load"}>Participants Load</option>
            <option value={"Load Protocol Data"}>Load Protocol Data</option>
          </NativeSelect>
        </div>

        {selectedOption === "Continuing Review" && <EProtocolDatePickers />}
        {selectedOption === "Participants Load" && (
          <UploadFile csvType={"participants"} />
        )}
        {selectedOption === "Load Protocol Data" && (
          <UploadFile csvType={"training"} />
        )}
      </FormControl>
    </div>
  );
}
