import axios from "axios";
import { handleCommonBRError } from "../components/utils";

export const downloadFromApi = function (
  link,
  setASAlertMessage,
  setDisplayASAlert
) {
  axios({
    url: link,
    method: "GET",
    responseType: "blob",
  })
    .then((res) => {
      const contentDisposition = res.headers["content-disposition"];
      const filename = /filename="(.+)"/.exec(contentDisposition)[1];
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(res.data);
      a.setAttribute("download", filename);
      a.click();
    })
    .catch((err) => {
      handleCommonBRError(err, setASAlertMessage, setDisplayASAlert);
    });
};
