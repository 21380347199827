import "date-fns";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import EProtocolPaginatedTable from "./EProtocolPaginatedTable";
import UploadToOnCore from "./UploadToOnCore";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { format } from "date-fns";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import OISBanner from "./OISBanner";
import { handleCommonBRError } from "./utils";
import { downloadFromApi } from "../services/downloadService";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    margin: theme.spacing(3),
  },
  textField: {
    width: 200,
    margin: theme.spacing(2),
  },
  buttonExtract: {
    color: "#ffffff",
    backgroundColor: "#800000",
    height: 39,
  },
  buttonUploadToOncore: {
    color: "#ffffff",
    backgroundColor: "#800000",
    height: 39,
  },
  buttonSmall: {
    margin: 3,
    fontSize: "xx-small",
    paddingTop: 2,
    paddingBottom: 2,
    paddingRight: 5,
    paddingLeft: 5,
  },
  extractedDataAndExportBtn: {
    //backgroundColor: "gray",
    display: "flex",
    justifyContent: "space-between",
  },
  buttonProgress: {
    color: "#800000",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  errorMsg: {
    color: "#FF0000",
  },
}));

export default function DatePickers() {
  const classes = useStyles();

  const [eProtocolRecords, setEProtocolRecords] = useState([]);
  const [uploadLink, setUploadLink] = useState(null);

  const [startDateFrom, setStartDateFrom] = useState(null);
  //const [minStartDateFrom, setMinStartDateFrom] = useState(null);
  //const [maxStartDateFrom, setMaxStartDateFrom] = useState(null);

  const [endDateTo, setEndDateTo] = useState(null);
  //const [minEndDateTo, setMinEndDateTo] = useState(null);
  //const [maxEndDateTo, setMaxEndDateTo] = useState(null);

  const [displayASAlert, setDisplayASAlert] = useState(false);
  const [ASAlertMessage, setASAlertMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleCloseASAlert = (value) => {
    setDisplayASAlert(false);
    setASAlertMessage(null);
  };

  const handleStartDateFromChange = (date) => {
    setStartDateFrom(date);
    //setMinEndDateTo(date);
  };

  const handleEndDateDateToChange = (date) => {
    setEndDateTo(date);
    //setMaxStartDateFrom(date);
  };

  const handleExtract = (selected) => {
    const isoStartDate = format(new Date(startDateFrom), "yyyy-MM-dd");
    const isoEndDate = format(new Date(endDateTo), "yyyy-MM-dd");
    if (!loading) {
      setLoading(true);
    }
    // OIS-973 eProtocolRecords is not getting cleaned up properly before fetching new data.
    setEProtocolRecords([]);

    axios
      .get(`/eprotocol/activity?start=${isoStartDate}&end=${isoEndDate}`)
      .then((res) => {
        setUploadLink(res.data._links.upload.href);
        setEProtocolRecords(res.data);
        setLoading(false);
      })
      .catch((err) => {
        handleCommonBRError(err, setASAlertMessage, setDisplayASAlert);
        setLoading(false);
      });
  };

  return (
    <div>
      <OISBanner
        onClose={handleCloseASAlert}
        open={displayASAlert}
        message={ASAlertMessage}
      />
      <form className={classes.container} noValidate>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container justify="space-around">
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              label="Start Date"
              id="start-date"
              value={startDateFrom}
              onChange={handleStartDateFromChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              label="End Date"
              id="end-date"
              value={endDateTo}
              onChange={handleEndDateDateToChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
            <Tooltip title="Extract from eProtocol" placement="top" arrow>
              <Button
                variant="contained"
                className={classes.buttonExtract}
                onClick={(evt) => {
                  handleExtract();
                }}
              >
                Extract
              </Button>
            </Tooltip>
            {loading && (
              <CircularProgress size={72} className={classes.buttonProgress} />
            )}
            <br />
          </Grid>
        </MuiPickersUtilsProvider>
      </form>
      {eProtocolRecords !== undefined &&
      eProtocolRecords.irbResources !== undefined &&
      eProtocolRecords.irbResources.length > 0 ? (
        <div>
          <br />
          <div className={classes.extractedDataAndExportBtn}>
            Extracted eProtocol data &nbsp;
            <Tooltip
              title="Export extracted eProtocol data into CSV file"
              placement="top"
              arrow
            >
              <Button
                variant="contained"
                color="primary"
                className={classes.buttonSmall}
                startIcon={<ImportExportIcon />}
                disabled={eProtocolRecords.length === 0 ? true : false}
                onClick={(evt) => {
                  downloadFromApi(
                    eProtocolRecords._links.download.href,
                    setASAlertMessage,
                    setDisplayASAlert
                  );
                }}
              >
                Export
              </Button>
            </Tooltip>
          </div>

          <br />
          <EProtocolPaginatedTable eProtocolRecords={eProtocolRecords} />
          <br />
          <br />
          <UploadToOnCore
            uploadLink={uploadLink}
            inputRecords={eProtocolRecords}
            csvType="eProtocol"
          />
        </div>
      ) : eProtocolRecords !== undefined &&
        startDateFrom !== null &&
        endDateTo !== null &&
        eProtocolRecords.length === undefined ? (
        <div className={classes.errorMsg}>
          <strong> No data found for the date range provided </strong>
        </div>
      ) : null}
    </div>
  );
}
