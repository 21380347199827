import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import NativeSelect from "@material-ui/core/NativeSelect";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import axios from "axios";
import { useAuth } from "../services/authService";

import OISBanner from "./OISBanner";
import { handleCommonBRError } from "./utils";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import { refreshActivityTable } from "./OISMain";
import { oncoreEnvsService } from "../services/oncoreEnvsService";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    margin: theme.spacing(3),
  },
  textField: {
    width: 200,
    margin: theme.spacing(2),
  },
  buttonExtract: {
    color: "#ffffff",
    backgroundColor: "#800000",
    height: 30,
  },
  buttonUploadToOncore: {
    color: "#ffffff",
    backgroundColor: "#800000",
    height: 30,
  },
}));

export default function UploadToOnCore(props) {
  const classes = useStyles();
  const file = props.file;
  const csvType = props.csvType;
  const uploadLink = props.uploadLink;
  const [
    selectedOnCoreDestinationEnvironmentOption,
    setSelectedOnCoreDestinationEnvironmentOption,
  ] = useState("Select OnCore environment");

  const [displayASAlert, setDisplayASAlert] = useState(false);
  const [ASAlertMessage, setASAlertMessage] = useState(null);

  const [hasOncoreProduction, setHasOncoreProduction] = useState(false);
  const [hasOncoreTango, setHasOncoreTango] = useState(false);
  const [hasOncoreTest, setHasOncoreTest] = useState(true);
  const [hasOncoreIndigo, setHasOncoreIndigo] = useState(true);
  const [hasOncoreTraining, setHasOncoreTraining] = useState(true);
  const [hasOncoreStaging, setHasOncoreStaging] = useState(true);
  const [hasOncoreCloudProduction, setHasOncoreCloudProduction] =
    useState(true);
  const [hasOncoreShadow, setHasOncoreShadow] = useState(true);

  useEffect(() => {
    setHasOncoreProduction(oncoreEnvsService.hasOncoreProduction());
    setHasOncoreTest(oncoreEnvsService.hasOncoreTest());
    setHasOncoreTango(oncoreEnvsService.hasOncoreTango());
    setHasOncoreIndigo(oncoreEnvsService.hasOncoreIndigo());
    setHasOncoreTraining(oncoreEnvsService.hasOncoreTraining());
    setHasOncoreStaging(oncoreEnvsService.hasOncoreStaging());
    setHasOncoreCloudProduction(oncoreEnvsService.hasOncoreCloudProduction());
    setHasOncoreShadow(oncoreEnvsService.hasOncoreShadow());
  }, []);

  const handleCloseASAlert = (value) => {
    setDisplayASAlert(false);
    setASAlertMessage(null);
  };

  const [openPopUp, setOpenPopUp] = useState(false);

  const handleOpenPopUp = () => {
    setOpenPopUp(true);
  };

  const handleClosePopUp = () => {
    setOpenPopUp(false);
  };

  const handleOnCoreEnvironmentChange = (event) => {
    setSelectedOnCoreDestinationEnvironmentOption(event.target.value);
  };

  const token = "Bearer ".concat(useAuth().keycloak.token);

  const capitalize = (s) => {
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  const getPopupMessage = (onCoreEnv) => {
    switch (csvType) {
      case "eProtocol":
        return (
          "Continue Review file ready to parse & upload to OnCore " +
          onCoreEnv +
          " environment."
        );
      case "participants":
        return (
          "Participant Load file ready to parse & upload to OnCore " +
          onCoreEnv +
          " environment."
        );
      case "training":
        return (
          "Training Data file ready to parse & upload to OnCore " +
          onCoreEnv +
          " environment."
        );
      default:
        return "unknown csvType";
    }
  };

  const handleUploadToOncore = (selected) => {
    let url = "";
    switch (csvType) {
      case "eProtocol":
        url = uploadLink.replace(
          "{instance}",
          selectedOnCoreDestinationEnvironmentOption
        );
        break;
      case "participants":
        url =
          "/imports/participant-registration?instance=" +
          selectedOnCoreDestinationEnvironmentOption;
        break;
      case "training":
        url =
          "/imports/training-data?instance=" +
          selectedOnCoreDestinationEnvironmentOption;
        break;
      default:
        url = "https://jsonplaceholder.typicode.com/posts" + csvType;
        break;
    }

    const formData = new FormData();
    formData.append("file", file);

    axios
      .post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: token,
        },
      })
      .then((res) => {})
      .catch((err) => {
        handleCommonBRError(err, setASAlertMessage, setDisplayASAlert);
      });

    refreshActivityTable(true);
    handleClosePopUp();
  };

  return (
    <div>
      <OISBanner
        onClose={handleCloseASAlert}
        open={displayASAlert}
        message={ASAlertMessage}
      />
      Upload above data to OnCore
      <br />
      <br />
      <span>
        <FormControl className={classes.formControl}>
          <NativeSelect
            enabled="true"
            value={selectedOnCoreDestinationEnvironmentOption}
            onChange={handleOnCoreEnvironmentChange}
            inputProps={{
              name: "OnCoreDestinationEnvironment",
              id: "OnCoreDestinationEnvironment-native-label-placeholder",
            }}
          >
            <option value="Select OnCore environment">
              Select OnCore Environment
            </option>
            {hasOncoreProduction ? (
              <option value={"production"}>Production</option>
            ) : (
              ""
            )}
            {hasOncoreTango ? <option value={"tango"}>Tango</option> : ""}
            {hasOncoreTest ? <option value={"test"}>Test</option> : ""}
            {hasOncoreIndigo ? <option value={"indigo"}>Indigo</option> : ""}
            {hasOncoreTraining ? (
              <option value={"training"}>Training</option>
            ) : (
              ""
            )}
            {hasOncoreStaging ? <option value={"staging"}>Staging</option> : ""}
            {hasOncoreCloudProduction ? (
              <option value={"cloudproduction"}>Cloud Production</option>
            ) : (
              ""
            )}
            {hasOncoreShadow ? <option value={"shadow"}>Shadow</option> : ""}
          </NativeSelect>
        </FormControl>
      </span>
      <span>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <FormControl>
          <span>
            <Tooltip title="Upload data to OnCore" placement="top" arrow>
              <span>
                <Button
                  variant="contained"
                  className={classes.buttonUploadToOncore}
                  disabled={
                    selectedOnCoreDestinationEnvironmentOption ===
                    "Select OnCore environment"
                  }
                  onClick={(evt) => {
                    handleOpenPopUp();
                  }}
                >
                  Upload
                </Button>
                <Dialog
                  open={openPopUp}
                  onClose={handleClosePopUp}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {capitalize(selectedOnCoreDestinationEnvironmentOption)}{" "}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      {getPopupMessage(
                        capitalize(selectedOnCoreDestinationEnvironmentOption)
                      )}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClosePopUp} color="primary">
                      Cancel
                    </Button>
                    <Button
                      onClick={handleUploadToOncore}
                      color="primary"
                      autoFocus
                    >
                      OK
                    </Button>
                  </DialogActions>
                </Dialog>
              </span>
            </Tooltip>
          </span>
        </FormControl>
      </span>
      <br />
      <br />
    </div>
  );
}
