import React from "react";
import { downloadFromApi } from "../services/downloadService";

function DownloadLink(props) {
  return (
    <a
      href={props.href}
      onClick={(evt) => {
        evt.preventDefault();
        downloadFromApi(props.href);
      }}
    >
      {props.filename}
    </a>
  );
}

export default DownloadLink;
