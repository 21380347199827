// This is telling which oncore services are supposed to
// be used by this OIS instance.
// This here is a very cheap solution.
//
// If the wrong service is allowed, it still will not work, because
// The api will not have the right credentials.

const ONCORE_ENVS_BY_INSTANCE = {
  dev: ["staging"],
  stage: ["shadow", "staging"],
  prod: ["training", "cloudproduction", "shadow", "staging"],
  default: ["staging"],
};

const hasAccessTo = function (oncoreEnv) {
  if (window.DEPLOY_ENV in ONCORE_ENVS_BY_INSTANCE) {
    return ONCORE_ENVS_BY_INSTANCE[window.DEPLOY_ENV].indexOf(oncoreEnv) >= 0;
  } else {
    return ONCORE_ENVS_BY_INSTANCE["default"].indexOf(oncoreEnv) >= 0;
  }
};

export const oncoreEnvsService = {
  hasOncoreProduction: () => hasAccessTo("production"),
  hasOncoreTest: () => hasAccessTo("test"),
  hasOncoreTango: () => hasAccessTo("tango"),
  hasOncoreIndigo: () => hasAccessTo("indigo"),
  hasOncoreTraining: () => hasAccessTo("training"),
  hasOncoreStaging: () => hasAccessTo("staging"),
  hasOncoreCloudProduction: () => hasAccessTo("cloudproduction"),
  hasOncoreShadow: () => hasAccessTo("shadow"),
};
