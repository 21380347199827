import React from "react";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";

const OISBanner = (props) => {
  const { onClose, open, message } = props;

  let tTimeOut;
  if (open) {
    tTimeOut = setTimeout(() => onClose(false), 5000);
  }
  return (
    <Collapse in={open}>
      <Alert
        severity={message ? message.type : "success"}
        action={
          <IconButton
            color="inherit"
            size="small"
            onClick={() => {
              if (tTimeOut) {
                clearTimeout(tTimeOut);
              }
              onClose(false);
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
      >
        {message ? message.detail : ""}
      </Alert>
    </Collapse>
  );
};

export default OISBanner;
