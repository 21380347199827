import React from "react";
import Button from "@material-ui/core/Button";
import InfoIcon from "@material-ui/icons/Info";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";

const useStyles1 = makeStyles((theme) => ({
  root: {
    "& p": {
      marginTop: 1,
      marginBottom: 0,
    },
    lineHeight: "1em",
    minHeight: "3em",
    paddingTop: 0,
  },
  dialogTitle: {
    paddingBottom: 2,
    lineHeight: "2em",
  },
  errContent: {
    "& div": {
      marginBottom: "2em",
    },
  },
}));

export default function ScrollDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const classes = useStyles1();

  return (
    <span>
      <InfoIcon onClick={handleClickOpen("paper")}></InfoIcon>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        style={{ width: "90%" }}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle className={classes.dialogTitle} id="scroll-dialog-title">
          Info:
        </DialogTitle>
        <DialogContent className={classes.root} tabIndex={-1}>
          <p>
            <b>Transaction Type:</b> {props.rowType}
          </p>
          <p>
            <b>File Name:</b> {props.filename}
          </p>
        </DialogContent>
        <DialogContent className={classes.errContent} dividers={true}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <div>
              {props["recentActivitySummary"][0] !== undefined &&
              props["recentActivitySummary"][0].errors !== undefined &&
              props["recentActivitySummary"][0].errors.length > 0 ? (
                <b>Errors:</b>
              ) : (
                <b>No Errors:</b>
              )}
              {props["recentActivitySummary"][0] !== undefined &&
              props["recentActivitySummary"][0].errors !== undefined
                ? props["recentActivitySummary"][0].errors.map(function (
                    name,
                    index
                  ) {
                    console.log();
                    return <li key={index}>{name}</li>;
                  })
                : null}
            </div>
            <div>
              {props["recentActivitySummary"][0] !== undefined &&
              props["recentActivitySummary"][0].messages !== undefined &&
              props["recentActivitySummary"][0].messages.length > 0 ? (
                <b>Messages:</b>
              ) : (
                <b>No Messages:</b>
              )}
              {props["recentActivitySummary"][0] !== undefined &&
              props["recentActivitySummary"][0].messages !== undefined
                ? props["recentActivitySummary"][0].messages.map(function (
                    name,
                    index
                  ) {
                    console.log();
                    return <li key={index}>{name}</li>;
                  })
                : null}
            </div>
            <div>
              {props["recentActivitySummary"][0] !== undefined &&
              props["recentActivitySummary"][0].warnings !== undefined &&
              props["recentActivitySummary"][0].warnings.length > 0 ? (
                <b>Warnings:</b>
              ) : (
                <b>No Warnings:</b>
              )}
              {props["recentActivitySummary"][0] !== undefined &&
              props["recentActivitySummary"][0].warnings !== undefined
                ? props["recentActivitySummary"][0].warnings.map(function (
                    name,
                    index
                  ) {
                    console.log();
                    return <li key={index}>{name}</li>;
                  })
                : null}
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </span>
  );
}
