import React from "react";
import PropTypes from "prop-types";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import { TableContainer } from "@material-ui/core";
import TableHead from "@material-ui/core/TableHead";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 12,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  //const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStyles2 = makeStyles({
  table: {
    border: 1,
    minWidth: 500,
  },
});

function CSVPaginationTable(props) {
  const classes = useStyles2();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const rows = props.inputRecords || [];
  const csvType = props.csvType;
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TableContainer component={Paper}>
      <Table
        className={classes.table}
        size="small"
        aria-label="custom pagination table"
      >
        <TableHead>
          {csvType === "participants" ? (
            <TableRow>
              <StyledTableCell>Context</StyledTableCell>
              <StyledTableCell align="left">Protocol Number</StyledTableCell>
              <StyledTableCell align="left">Study Site</StyledTableCell>
              <StyledTableCell align="left">MRN</StyledTableCell>
              <StyledTableCell align="left">Race</StyledTableCell>

              <StyledTableCell align="left">First Name</StyledTableCell>
              <StyledTableCell align="left">Last Name</StyledTableCell>
              <StyledTableCell align="left">Middle Name</StyledTableCell>

              <StyledTableCell align="left">Suffix</StyledTableCell>
              <StyledTableCell align="left">Birth Date</StyledTableCell>

              <StyledTableCell align="left">Gender</StyledTableCell>
              <StyledTableCell align="left">Ethnicity</StyledTableCell>
              <StyledTableCell align="left">
                Approximate Birth Date Flag
              </StyledTableCell>

              <StyledTableCell align="left">
                Birth Date Not Available Flag
              </StyledTableCell>
              <StyledTableCell align="left">
                Approximate Expired Date Flag
              </StyledTableCell>
              <StyledTableCell align="left">Expired Date</StyledTableCell>

              <StyledTableCell align="left">ZIP At Registration</StyledTableCell>
            </TableRow>
          ) : (
            <TableRow>
              <StyledTableCell>Protocol No</StyledTableCell>
              <StyledTableCell align="left">
                Organizational Unit
              </StyledTableCell>
              <StyledTableCell align="left">Department</StyledTableCell>
              <StyledTableCell align="left">Library</StyledTableCell>
              <StyledTableCell align="left">IRB No</StyledTableCell>
              <StyledTableCell align="left">Title</StyledTableCell>
              <StyledTableCell align="left">
                Estimated Completion Date
              </StyledTableCell>
              <StyledTableCell align="left">Involves Therapy</StyledTableCell>
              <StyledTableCell align="left">
                Summary Accrual Info Only
              </StyledTableCell>
              <StyledTableCell align="left">Protocol Type</StyledTableCell>
              <StyledTableCell align="left">Management Group</StyledTableCell>
              <StyledTableCell align="left">Staff Pd</StyledTableCell>
              <StyledTableCell align="left">Staff Primary CRC</StyledTableCell>
              <StyledTableCell align="left">Institution Sites</StyledTableCell>
            </TableRow>
          )}
        </TableHead>
        {csvType === "participants" ? (
          <TableBody>
            {(rowsPerPage > 0
              ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : rows
            ).map((row, index) => (
              <StyledTableRow key={index}>
                <TableCell align="left">{row["Context"]}</TableCell>
                <TableCell component="th" scope="row">
                  {row["Protocol Number"]}
                </TableCell>
                <TableCell align="left">{row["Study Site"]}</TableCell>
                <TableCell align="left">{row["MRN"]}</TableCell>
                <TableCell align="left">{row["Race"]}</TableCell>

                <TableCell align="left">{row["First Name"]}</TableCell>
                <TableCell align="left">{row["Last Name"]}</TableCell>
                <TableCell align="left">{row["Middle Name"]}</TableCell>

                <TableCell align="left">{row["Suffix"]}</TableCell>
                <TableCell align="left">{row["Birth Date"]}</TableCell>

                <TableCell align="left">{row["Gender"]}</TableCell>
                <TableCell align="left">{row["Ethnicity"]}</TableCell>
                <TableCell align="left">
                  {row["Approximate Birth Date Flag"]}
                </TableCell>
                <TableCell align="left">
                  {row["Birth Date Not Available Flag"]}
                </TableCell>

                <TableCell align="left">
                  {row["Approximate Expired Date Flag"]}
                </TableCell>
                <TableCell align="left">{row["Expired Date"]}</TableCell>
                <TableCell align="left">{row["ZIP At Registration"]}</TableCell>
              </StyledTableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        ) : (
          <TableBody>
            {(rowsPerPage > 0
              ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : rows
            ).map((row, index) => (
              <StyledTableRow key={index}>
                <TableCell component="th" scope="row">
                  {row["Protocol No"]}
                </TableCell>
                <TableCell align="left">{row["Organizational Unit"]}</TableCell>
                <TableCell align="left">{row["Department"]}</TableCell>
                <TableCell align="left">{row["Library"]}</TableCell>
                <TableCell align="left">{row["IRB No"]}</TableCell>
                <TableCell align="left">{row["Title"]}</TableCell>
                <TableCell align="left">
                  {row["Estimated Completion Date"]}
                </TableCell>
                <TableCell align="left">{row["Involves Therapy"]}</TableCell>
                <TableCell align="left">
                  {row["Summary Accrual Info Only"]}
                </TableCell>

                <TableCell align="left">{row["Protocol Type"]}</TableCell>
                <TableCell align="left">{row["Management Group"]}</TableCell>
                <TableCell align="left">{row["StaffPd"]}</TableCell>

                <TableCell align="left">{row["StaffPrimaryCRC"]}</TableCell>
                <TableCell align="left">{row["InstitutionSites"]}</TableCell>
              </StyledTableRow>

              // Protocol No,
              // Organizational Unit,
              // Department,
              // Library,
              // IRB No,
              // Title,
              // Estimated Completion Date,
              // Involves Therapy,
              // Summary Accrual Info Only,
              // Protocol Type,
              // Management Group,
              // StaffPd,
              // StaffPrimaryCRC,
              // InstitutionSites
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        )}
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
              colSpan={0}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { "aria-label": "rows per page" },
                native: true,
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}

export default CSVPaginationTable;
